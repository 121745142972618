import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateQuestionnaireResponseService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/candidate-questionnaire-response';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    listAllQuestionsWithAverageRating(data={},index = null) {
        let url = `${this.#api}/average-ratings/by-question`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
}